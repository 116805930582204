import striptags from 'striptags';
import { decode } from 'he';
import { truncate } from 'lodash';

// @see https://stackoverflow.com/a/38150679
const teaserMaxLength = 190;

function trimWords(string) {
  return truncate(string, { length: teaserMaxLength, omission: ' …', separator: /,?\.* +/ });
}

export function sanitizeTeaserText(text) {
  return trimWords(decode(striptags(text)));
}

export function sanitizeTeaserTitle(title) {
  return decode(title);
}

/**
 * Convert flat array list containing items with parent id keys to nested tree.
 *
 * @see https://stackoverflow.com/a/55241491
 */
export function listToTree(items, rootId = null, {
  idKey = 'wordpress_id',
  parentIdKey = 'wordpress_parent',
  dataKey = null,
} = {}) {
  return (
    items
      .filter((item) => item[parentIdKey] === rootId)
      .map((item) => {
        const data = dataKey ? { [dataKey]: item } : item;
        return (
          {
            ...data,
            children: listToTree(items, item[idKey], {
              idKey,
              parentIdKey,
              dataKey,
            }),
          }
        );
      })
  );
}
